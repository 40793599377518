<template>
    <div>
        <errors-bag-list></errors-bag-list>

        <b-card no-body>
            <b-card-header header-tag="header">
                <b-row class='mb-3'>
                    <b-col md='12' class='text-right'>
                        <b-button variant='primary' class='mr-2' @click='getPrintQueue'>Обновить</b-button>
                        <b-button variant='danger' @click='openClearPrintQueueDialog( null )'>
                            Очистить <b>все очереди</b>
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-header>

            <b-table stipred :responsive="true" :items="queues" :fields="fields" head-variant="dark">
                <template #cell(actions)="data">
                    <b-button size="md" variant="danger" @click="openClearPrintQueueDialog( data.item )">
                        <i class="fas fa-trash-alt" style="color:#fff;"></i>
                    </b-button>
                </template>
            </b-table>
        </b-card>

        <b-modal centered hide-footer :ref="dialogKey" class="clear-print-queue-dialog text-center" @hide="actionsAfterHide">
            <div class='mt-4'>
                <span v-html='dialogMessage'></span>
            </div>

            <div class='mt-3'>
                <button class="btn btn-danger" @click="clearPrintQueue">
                    <span v-html='dialogButton'></span>
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import ErrorsBag from '../../../utils/errorsBag';
    import ErrorsBagList from '../../_common/ErrorsBagList';

    import { PRINT_QUEUES } from '../../../utils/endpoints';

    export default {
        name: "PrintQueueList",
        components: { ErrorsBagList },
        data() {
            return {
                ErrorsBag,

                queues: [],
                fields: [
                    { key: 'name', label: 'Наименование таблицы' },
                    { key: 'count', label: 'Количество записей' },
                    { key: 'actions', label: 'Действия' }
                ],

                queue: null
            };
        },
        computed: {
            dialogKey() {
                return 'ClearPrintQueueDialog';
            },
            dialogButton() {
                return 'Очистить ' +
                    ( this.namingQueueExists ? 'очередь <b>' + this.queue.name + '</b>' : '<b>все очереди</b>' );
            },
            dialogMessage() {
                return 'Вы уверены, что хотите очистить ' +
                    ( this.namingQueueExists ? 'очередь <b>' + this.queue.name + '</b>' : '<b>все очереди</b>' ) + '?';
            },

            namingQueueExists() {
                return this.queue !== null && typeof this.queue === 'object';
            }
        },
        methods: {
            /**
             * Получаем очередь
             */
            getPrintQueue() {
                ErrorsBag.discard();

                this.$http
                    .get( PRINT_QUEUES + '/list' )
                    .then(
                        response => this.queues = response.data.data,
                        response => ErrorsBag.fill( response.body )
                    );
            },

            /**
             * Очищаем очередь
             */
            clearPrintQueue() {
                ErrorsBag.discard();

                let params = this.namingQueueExists ? { queue: this.queue.queue } : {};

                this.$http
                    .delete( PRINT_QUEUES + '/clear', { params } )
                    .then(
                        () => {
                            this.$refs[ this.dialogKey ].hide();
                            this.actionsAfterHide();
                            this.getPrintQueue();
                        },
                        response => ErrorsBag.fill( response.body )
                    );
            },

            /**
             * Открываем диалог удаления очереди
             *
             * @param queue
             */
            openClearPrintQueueDialog( queue ) {
                this.queue = queue ? queue : true;

                this.$refs[ this.dialogKey ].show();
            },

            /**
             * Действия после закрытия диалога
             */
            actionsAfterHide() {
                this.queue = null;

                ErrorsBag.discard();
            }
        },
        mounted() {
            this.getPrintQueue();
        }
    }
</script>

<style scoped>
    >>> .clear-print-queue-dialog .modal-body {
        padding: .75rem 0.5rem;
    }

    >>> .clear-print-queue-dialog .modal-header {
        padding: .5rem;
    }
</style>
